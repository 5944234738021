// import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import "./journals.css";

function Journals({ journalData }) {
  const {t} = useTranslation();
  return (
    <section>
      <div className="our-jurnals">
        <div className="container">
          <h1 className="title-our-jurnal text-center">{t('navbar.our_journals')}</h1>
          <div className="row">
            {journalData.map((value) => {
              return (
                <div className="col-md-4 my-2" key={value.id}>
                  <div className="card" >
                    <img src={value.img} width="100%"  className="card-img-top" alt="..." />
                    <div className="card-body text-center">
                      {/* <h5 className="card-title pt-3">{value.name}</h5> */}
                      {/* <h5 className="card-title pt-3">{t('journals.journal_1')}</h5> */}
                      {/* <p className="card-text">{value.title}</p> */}
                      <a href={`${value.id}`} target="blank" className="card-btn">{t('hero.btn')}</a>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Journals;
