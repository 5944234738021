import React from 'react'
import { Link } from 'react-router-dom'
import { BiMapAlt, BiPhoneIncoming, BiMailSend, BiLogoTelegram, BiLogoFacebook, BiLogoInstagram, BiLogoLinkedinSquare } from "react-icons/bi";
import { useTranslation } from 'react-i18next';
import logo from '../../assets/img/logo/LOGO_light.png'

function Footer() {
    const { t } = useTranslation();
    return (
        <>
            <div className="footer-area-wrapper  bg-dark">
                <div className="footer-area section-space--ptb_80">
                    <div className="container">
                        <div className="row footer-widget-wrapper">
                            <div className="col-lg-4 col-md-6 col-sm-6 footer-widget">
                                <div className="footer-widget__logo mb-30">
                                    <Link to="/">
                                        <img src={logo} width="180" height="48" className="img-fluid" alt="" />
                                    </Link>
                                </div>
                                <ul className="footer-widget__list text-white">
                                    <p style={{textAlign: 'justify'}}>{t('footer.desc')}</p>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 footer-widget">
                                <h6 className="footer-widget__title mb-20">{t('footer.tittle')}</h6>
                                <ul className="footer-widget__list">
                                    <li><a href="#" className="hover-style-link text-white"><BiMapAlt style={{ fontSize: "30px", marginRight: "8px", color: "#fff" }} />{t('footer.adress')}</a></li>
                                    <li><a href="tel:+998935271622" className="hover-style-link text-white"><BiPhoneIncoming style={{ fontSize: "30px", marginRight: "8px", color: "#fff" }} />(+998)935271622</a></li>
                                    <li><a href="mailto:contact@aeroland.com" className="hover-style-link text-white"><BiMailSend style={{ fontSize: "30px", marginRight: "8px", color: "#fff" }} />ilmiyizlanuvchi@gmail.com</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 footer-widget">
                                <h6 className="footer-widget__title mb-20">{t('footer.links')}</h6>
                                <ul className="footer-widget__list">
                                    <li><a href="#" className="hover-style-link text-white">{t('footer.articles')}</a></li>
                                    <li><a href="#" className="hover-style-link text-white">{t('footer.conferences')}</a></li>
                                    <li><a href="#" className="hover-style-link text-white">{t('footer.services')}</a></li>
                                    <li><a href="#" className="hover-style-link text-white">{t('footer.blog')}</a></li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-copyright-area section-space--pb_30">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 text-center text-md-start text-white">
                                <span className="copyright-text">© 2023 uznauka.uz. <a href="https://masterdev.uz/" target="blank">{t('footer.license')}</a></span>
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                <div className="counter-site">

                                <script language="javascript" type="text/javascript"></script>
                                    <script language="javascript1.1" type="text/javascript">

                                        top_js="1.1";top_r+="&j="+(navigator.javaEnabled()?"Y":"N")

                                    </script>
                                    <script language="javascript1.2" type="text/javascript">

                                        top_js="1.2";top_r+="&wh="+screen.width+'x'+screen.height+"&px="+
                                        (((navigator.appName.substring(0,3)=="Mic"))?screen.colorDepth:screen.pixelDepth)

                                    </script>
                                    <script language="javascript1.3" type="text/javascript">

                                        top_js="1.3";

                                    </script>
                                    <script language="JavaScript" type="text/javascript">

                                        top_rat="&col=0063AF&t=ffffff&p=E6850F";top_r+="&js="+top_js+"";document.write(<a href="http://www.uz/uz/res/visitor/index?id=47250" target="top"><img src="https://cnt0.www.uz/counter/collect?'+top_r+top_rat+'" width="88" height="31" border="0" alt="Топ рейтинг www.uz" /></a>)
                                    </script><noscript><a href="http://www.uz/uz/res/visitor/index?id=47250" target="top"><img height="31" src="https://cnt0.www.uz/counter/collect?id=47250&pg=http%3A//uzinfocom.uz&&col=0063AF&amp;t=ffffff&amp;p=E6850F" width="88" border="0" alt="Топ рейтинг www.uz" /></a></noscript>
                                </div>

                                

                                <ul className="list ht-social-networks solid-rounded-icon">
                                    <li className="item">
                                        <a href="https://t.me/uznauka_uz" target="_blank" aria-label="Twitter" className="social-link hint--bounce hint--top hint--primary text-white">
                                            <BiLogoTelegram className='link-icon' />
                                        </a>
                                    </li>
                                    <li className="item">
                                        <a href="https://facebook.com" target="_blank" aria-label="Facebook" className="social-link hint--bounce hint--top hint--primary text-white">
                                            <BiLogoFacebook className='social-icon fab fa-facebook-f' />
                                        </a>
                                    </li>
                                    <li className="item">
                                        <a href="https://instagram.com" target="_blank" aria-label="Instagram" className="social-link hint--bounce hint--top hint--primary text-white">
                                            <BiLogoInstagram className='social-icon  fab fa-instagram' />
                                        </a>
                                    </li>
                                    <li className="item">
                                        <a href="https://linkedin.com" target="_blank" aria-label="Linkedin" className="social-link hint--bounce hint--top hint--primary text-white">
                                            <BiLogoLinkedinSquare className='social-icon fab fa-linkedin' />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer