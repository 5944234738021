import { useEffect } from 'react'
import {Link} from 'react-router-dom'
import Rightside from '../../components/rightside/Rightside'
import {articles} from '../../data' 

import './autors.css'

function Turdialiyev_M() {

    useEffect(()=> {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[])

    return (
        <div className=''>
            <div className='journal-hero'>
                <div className="journal-hero-img">
                    <div className="journal-hero-title">
                        <h1 className='journal-hero-title-name' itemprop="name">ДВОЙНАЯ ПРАВОВАЯ СИСТЕМА В МЕЖДУНАРОДНЫХ ФИНАНСОВЫХ ЦЕНТРАХ</h1>
                    </div>
                </div>
            </div>
            <div className='article-main container my-5'>
                <span>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Bosh sahifa</Link></li>
                        <li className="breadcrumb-item active" aria-current="page" ><Link to="/firstjournal">Jurnal </Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Turdialiev M</li>
                    </ol>
                </span>
                <div className="row g-5">
                    <div className="col-md-8">
                                <div className="blog-post shadow p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <span className="last_posts__img">
                                                <img src="" className='img-fluid' />
                                            </span>
                                        </div>
                                        <div className="">
                                            <div className="last_posts__big">
                                                <div className="last_posts">
                                                    <span className="last_posts__content" itemscope itemtype = "https://schema.org/ScholarlyArticle">
                                                        <div className="article-name">
                                                            <h5 itemprop="autor">{articles[0].autor}</h5>
                                                            <p>{articles[0].degree}</p><hr />
                                                        </div>
                                                        <span className="article-keywords">
                                                            <span itemprop="keywords">Ключевые слова</span>
                                                            <h5>{articles[0].keywords}</h5><hr /><br />
                                                        </span>
                                                        <span className="article-abstract">
                                                            <span>Аннотация</span>
                                                            <p itemprop="abstract">{articles[0].abstract}</p>
                                                        </span><hr /><br />
                                                        <div className="article-references">
                                                            <span>Библиографические ссылки</span>
                                                            <ul>
                                                                <li itemprop='citation'>{articles[0].references[0]}</li><br />
                                                                <li itemprop='citation'>{articles[0].references[1]}</li><br />
                                                                <li itemprop='citation'>{articles[0].references[2]}</li><br />
                                                                <li itemprop='citation'>{articles[0].references[3]}</li><hr /><br />
                                                            </ul>
                                                        </div>
                                                        <span className='journal-pdf'>
                                                            <button className='btn btn-outline-primary'><a href={articles[0].pdf}>PDF</a></button>
                                                            {/* <iframe src={articles[0].pdf}  itemprop='url'></iframe> */}
                                                        </span>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <Articles /> */}
                    </div>

                    {/* /////////////// RIGHT SIDE /////////// */}

                    <Rightside />
                </div>

            </div>
        </div>
    )
}

export default Turdialiyev_M