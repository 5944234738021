import { useRef } from 'react'
import Yandexmaps from '../yandexmaps/Yandexmaps'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
import 'react-toastify/dist/ReactToastify.css';
import './location.css'

function Location() {
    const { t } = useTranslation();
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_xozy814', 'template_2trile5', form.current, '8TAw6TONl_S64wGdM')
            .then((result) => {
                console.log(result.text);
                toast.success("Ma'lumot yuborildi!!!")
            }, (error) => {
                console.log(error.text);
                toast.error(error)
            });
        e.target.reset()
    }
    return (
        <div className='location'>
            <div className="form-component m-5">
                <div className="container">
                <ToastContainer />
                    <h1 className='title-our-jurnal text-center mb-5'>{t('location.title')}</h1>
                    <div className="row">
                        <div className="col-md-6">
                            <form ref={form} onSubmit={sendEmail}>
                                <label htmlFor="validationCustom01" className="form-label mt-2">{t('location.name')}</label>
                                <input type="text" className="form-control" id="validationCustom01" name='user_name' required />

                                <label htmlFor="validationCustom02" className="form-label mt-2">{t('location.last_name')}</label>
                                <input type="text" className="form-control" id="validationCustom02" name='user_last_name' required />

                                <label htmlFor="validationCustom03" className="form-label mt-2">{t('location.email')}</label>
                                <input type="email" className="form-control" id="validationCustom03" name='user_email' required />

                                {/* <label htmlFor="validationCustom04" className="form-label mt-2">{t('location.email')}</label>
                                <input type="file" className="form-control" id="validationCustom04" name='user_file' required /> */}

                                <label htmlFor="validationCustom04" className="form-label mt-2">{t('location.suggestions_and_thoughts')}</label>
                                <textarea className="form-control" id="validationCustom04" name='message' style={{ height: "100px" }} required ></textarea>


                                <button className=" contact-btn-primary mt-4" type="submit">{t('location.btn')}</button>
                            </form>
                        </div>
                        <div className="col-md-6">
                            <div className="contact-img text-center">
                                <Yandexmaps />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Location