import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";

import i18n from "i18next";
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend'
import ContactForm from './components/location/Gpt';


i18n
.use(initReactI18next)
.use(LanguageDetector)
.use(HttpApi)
    .use(Backend)
    .init({
        supportedLng: ['ru', 'uz', 'en'],
        fallbackLng: 'ru',
        detection: {
            order: [ 'cookie', 'htmlTag','localStorage','path','subdomain'],
            caches: ['cookie']
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        },
        interpolation: {
            escapeValue: false
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <App />
        {/* <ContactForm /> */}
      </BrowserRouter>
    </Suspense>
);

