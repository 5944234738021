import "./navbar.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookie from "js-cookie";

import { BiChevronDown, BiChevronRight, BiGlobe } from "react-icons/bi";

const language = [
  {
    code: "ru",
    name: "Русский",
    country_code: "ru",
  },
  {
    code: "uz",
    name: "O'zbekcha",
    country_code: "uz",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
];

function Navbar() {
  const { t } = useTranslation();
  const currentLanguageCode = cookie.get("i18next") || "en";

  // useEffect(()=> {
  //    document.title = t('navbar.main')
  // }, [t])

  return (
    <nav className="navbar navbar-expand-lg ">
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon text-white"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav sub-navbar">
            <li className="nav-item">
              <Link to="/">
                <span>{t("navbar.main")}</span>
              </Link>
            </li>
            <li className="nav-item dropdown nav-top ">
              <Link
                to=""
                className="nav-link dropdown-toggle text-white"
                // role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>{t("navbar.journal")}</span>
              </Link>
              <ul className="dropdown-menu submenu">
                <li>
                  <a
                    className="dropdown-item"
                    href="https://journals.uznauka.uz/index.php/ojs/index"
                    target='blank'
                  >
                    <span>{t("journals.journal_1")}</span>
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://journals.uznauka.uz/index.php/lps/index"
                    target='blank'
                  >
                    <span>{t("journals.journal_2")} </span>
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://journals.uznauka.uz/index.php/sse/index"
                    target='blank'
                  >
                    <span>{t("journals.journal_3")}</span>
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://journals.uznauka.uz/index.php/stm/index"
                    target='blank'
                  >
                    {" "}
                    <span>{t("journals.journal_4")}</span>
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://journals.uznauka.uz/index.php/ies/index"
                    target='blank'
                  >
                    {" "}
                    <span>{t("journals.journal_5")}</span>
                  </a>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown nav-top">
              <a
                className="nav-link dropdown-toggle  text-white"
                href="https://conf.uznauka.uz/index.php/zttf/issue/archive"
                target='blank'
                target="_blank"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>{t("navbar.conference")}</span>
              </a>
              <ul className="dropdown-menu submenu">
                <li>
                  <a
                    className="dropdown-item"
                    href="https://conf.uznauka.uz/index.php/zttf/issue/view/6"
                    target='blank'
                  >
                    <span> {t("conferences.conf_2")}</span>
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://conf.uznauka.uz/index.php/zttf/issue/view/8"
                    target='blank'
                  >
                    <span> {t("conferences.conf_3")}</span>
                  </a>
                </li>

                <li>
                  <a
                    className="dropdown-item"
                    href="https://conf.uznauka.uz/index.php/zttf/issue/view/3"
                    target='blank'
                  >
                    <span> {t("conferences.conf_4")}</span>
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://conf.uznauka.uz/index.php/zttf/issue/view/5"
                    target='blank'
                  >
                    <span> {t("conferences.conf_5")}</span>
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://conf.uznauka.uz/index.php/zttf/issue/view/7"
                    target='blank'
                  >
                    <span> {t("conferences.conf_6")}</span>
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://conf.uznauka.uz/index.php/zttf/issue/view/2"
                    target='blank'
                  >
                    <span> {t("conferences.conf_7")}</span>
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://conf.uznauka.uz/index.php/zttf/issue/view/4"
                    target='blank'
                  >
                    <span> {t("conferences.conf_8")}</span>
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="">
                <span>{t("navbar.blog")}</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="">
                <span>{t("navbar.services")}</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact">
                <span>{t("navbar.contact")}</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="language-menu">
          <div className="d-flex justify-content-end">
            <div className="dropdown">
              <button
                className="btn btn-link dropdown-toggle "
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <BiGlobe style={{ fontSize: "30px" }} />
              </button>
              <ul className="dropdown-menu">
                {language.map((item) => {
                  return (
                    <li key={item.country_code}>
                      <button
                        className="dropdown-item"
                        onClick={() => i18next.changeLanguage(item.code)}
                        disabled={item.code === currentLanguageCode}
                      >
                        <span
                          className={`flag-icon flag-icon-${item.country_code}`}
                        ></span>
                        {item.name}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
