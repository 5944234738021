import { useState } from 'react'
import './counter.css'
import '../../App.css'

import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger'
import { useTranslation } from 'react-i18next';

function Counter() {
    const {t} = useTranslation();
    const [counterOn, setCounterOn] = useState(false)
    return (
        <>
            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                <div className="journal-counter">
                    <div className="fun-fact-wrapper bg-theme-default section-space--pb_30 section-space--pt_60">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3 col-sm-6 wow move-up animated" style={{ visibility: "visible" }}>
                                    <div className="fun-fact--two text-center">
                                        <div className="fun-fact__count counter">
                                            {counterOn && <CountUp start={0} end={500} duration={2} delay={0} />}
                                            +
                                        </div>
                                        <h6 className="fun-fact__text">{t('counter.articles')}</h6>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 wow move-up animated" style={{ visibility: "visible" }}>
                                    <div className="fun-fact--two text-center">
                                        <div className="fun-fact__count counter">
                                            {counterOn && <CountUp start={0} end={5} duration={2} delay={0} />}
                                            +
                                        </div>
                                        <h6 className="fun-fact__text">{t('counter.journals')}</h6>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 wow move-up animated" style={{ visibility: "visible" }}>
                                    <div className="fun-fact--two text-center">
                                        <div className="fun-fact__count counter">
                                            {counterOn && <CountUp start={0} end={7} duration={2} delay={0} />}
                                            +
                                        </div>
                                        <h6 className="fun-fact__text">{t('counter.conferences')}</h6>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 wow move-up animated" style={{ visibility: "visible" }}>
                                    <div className="fun-fact--two text-center">
                                        <div className="fun-fact__count counter">
                                            {counterOn && <CountUp start={0} end={0} duration={2} delay={0} />}
                                            +
                                        </div>
                                        <h6 className="fun-fact__text">{t('counter.posts')}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollTrigger>
        </>
    )
}

export default Counter