import React from 'react'
import Location from '../../components/location/Location';
import { useTranslation } from 'react-i18next';
import './contact.css'

function Contact() {
  const {t} = useTranslation();
  return (
    <div className='contact'>

      <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_box text-center">
                <h2 className="breadcrumb-title">{t('navbar.contact')}</h2>
                <ul className="breadcrumb-list">
                  <li className="breadcrumb-item"><a >{t('navbar.main')}</a></li>
                  <li className="breadcrumb-item active">{t('navbar.contact')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Location />

      

    </div>
  )
}

export default Contact