import { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import './firstjounal.css'
import {journalData} from '../../../data'
import Loading from '../../../components/loading/Loading'
import Modal from '../../../components/modal/Modal'
import Articles from '../../../components/aritcles/Articles'
import Rightside from '../../../components/rightside/Rightside'
import { useTranslation } from 'react-i18next';

function Firstjournal() {

    console.log(journalData);
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false)
    
  
    const closeModal = () => {
        setShowModal(!showModal)
    };

    useEffect(()=> {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[])

    return (
        <article>
            <div className='journal-hero'>
                <div className="journal-hero-img">
                    <div className="journal-hero-title">
                        <h1 className='journal-hero-title-name'>{t('journals.journal_1')}</h1>
                    </div>
                </div>
            </div>
            <div className='article-main container my-5'>
                <span>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">{t('navbar.main')}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{t('journals.journal_1')}</li>
                    </ol>
                </span>
                <div className="row g-5">
                    <div className="col-md-8">
                        <h3 className="pb-4 my-4 fst-italic border-bottom">
                            {t('journals.main_journals')}
                        </h3>
                                <div className="blog-post shadow p-3">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <span className="last_posts__img">
                                                <img src={journalData[0].img} className='img-fluid' />
                                            </span>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="last_posts__big">
                                                <div className="last_posts">
                                                    <span className="last_posts__content">
                                                        <div className="journal-name">
                                                            <h5>{journalData[0].name}</h5>
                                                        </div>
                                                        <span className="journal-desc">
                                                            <p>{journalData[0].title}</p>
                                                        </span>
                                                        <span className='journal-pdf'>

                                                            {/* {showModal && <Modal closeModal={closeModal}>
                                                                <iframe src={data.file} ></iframe>
                                                            </Modal>} */}
                                                        </span>
                                                    </span>
                                                </div>

                                                <div className="button-wrap mt-4">
                                                    <button type="button" className="journal-button-1" onClick={() => setShowModal(true)} >
                                                    {t('btn.view_journal')}
                                                    </button>
                                                    <button className='journal-button-1'> {t('btn.current_issue')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Articles  journalData={journalData}/>
                    </div>

                    {/* /////////////// RIGHT SIDE /////////// */}

                    <Rightside />
                </div>

            </div>
        </article >
    )
}

export default Firstjournal