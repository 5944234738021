import React from 'react'
import './style.css'

function Pagenotfound() {
  return (
    <div className="container">
      <div className='page_wrepper'>
        <div className="page-404">
          <h1>404</h1>
        </div>
        <div className="page-title">
          <span>
            Oops! Page not found
          </span>
        </div>
      </div>
    </div>
  )
}

export default Pagenotfound