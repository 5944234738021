export const journalData = [
  {
    id: "https://journals.uznauka.uz/index.php/ojs/index",
    img: "/assets/img/journals/science_1.jpg",
    name: "Наука и общество",
    title: "",
    describtion: "",
    file: "",
  },
  {
    id: "https://journals.uznauka.uz/index.php/stm/index",
    img: "/assets/img/journals/tibbiyotda_ilmiy tendensiyalar.jpg",
    name: "Научные тенденции в медицине",
    title: "",
    describtion: "",
    file: "",
  },
  {
    id: "https://journals.uznauka.uz/index.php/ies/index",
    img: "/assets/img/journals/aniq_fanlarda_innovatsiyalar.jpg",
    name: "Инновации в точных науках",
    title: "",
    describtion: "",
    file: "",
  },
  {
    id: "https://journals.uznauka.uz/index.php/lps/index",
    img: "/assets/img/journals/huquq_siyosat.jpg",
    name: "Право, политика и общество",
    title: "",
    describtion: "",
    file: "",
  },
  {
    id: "https://journals.uznauka.uz/index.php/sse/index",
    img: "/assets/img/journals/Ijtimoiy_soha_va_talim.jpg",
    name: "Социальные науки и образование",
    title: "",
    describtion: "",
    file: "",
  },
];

export const indexes = [
  {
    id: 1,
    img: "/assets/img/base.png"
  },
  {
    id: 2,
    img: "/assets/img/index/cressfor.png",
  },
  {
    id: 3,
    img: "/assets/img/index/dimensions.png",
  },
  {
    id: 4,
    img: "/assets/img/index/google.png",
  },
  {
    id: 5,
    img: "/assets/img/index/index.png",
  },
  {
    id: 6,
    img: "/assets/img/index/issn.png",
  },
  {
    id: 7,
    img: "/assets/img/index/ssrn.png",
  },
];

export const conferences = [
  {
    id: 1,
    name: "Artboard_1",
    img: "/assets/img/conf/Artboard_1.png",
  },
  {
    id: 2,
    name: "Artboard_2",
    img: "/assets/img/conf/Artboard_2.png",
  },
  {
    id: 3,
    name: "Artboard_3",
    img: "/assets/img/conf/Artboard_3.png",
  },
  {
    id: 4,
    name: "Artboard_4",
    img: "/assets/img/conf/Artboard_4.png",
  },
  {
    id: 5,
    name: "Artboard_5",
    img: "/assets/img/conf/Artboard_5.png",
  },
  {
    id: 6,
    name: "Artboard_6",
    img: "/assets/img/conf/Artboard_6.png",
  },
  {
    id: 7,
    name: "Artboard_7",
    img: "/assets/img/conf/Artboard_7.png",
  },
  {
    id: 8,
    name: "Artboard_8",
    img: "/assets/img/conf/Artboard_8.png",
  },
];

export const articles = [
  {
    id: "1",
    name: "ДВОЙНАЯ ПРАВОВАЯ СИСТЕМА В МЕЖДУНАРОДНЫХ ФИНАНСОВЫХ ЦЕНТРАХ",
    autor: "Турдиалиев Муҳаммадали Пўлатжон ўғли",
    degree: "Старший преподаватель",
    keywords:
      "двойная правовая система, международные финансовые центры, юрисдикции, финансовая деятельность",
    abstract:
      "Данная работа исследует концепцию 'двойной правовой системы' в контексте международных финансовых центров (МФЦ). Работа рассматривает основные аспекты и характеристики такой системы, которая предполагает сосуществование различных правовых норм и юрисдикций внутри одного МФЦ. Будут проанализированы основные причины возникновения двойных правовых систем в МФЦ, а также их влияние на финансовую деятельность и инвестиции.",
    references: [
      "Крутовская, А.А., 2017. Правовые аспекты функционирования международных финансовых центров. Отечественная юриспруденция, (1 (15)), pp.49-51;",
      "Укин, С.К. and Батырбекова, Д.С., 2018. Действие актов Центра Международного финансового центра 'Астана' и особенности их исполнения во времени, пространстве и по кругу лиц. Вестник Института законодательства и правовой информации Республики Казахстан, (4 (53)), pp.41-49;",
      "Нурбекова, Г.Т. and Исабекова, В.С., 2021. НЕКОТОРЫЕ ВОПРОСЫ ОРГАНИЗАЦИИ ДЕЯТЕЛЬНОСТИ СУДА МЕЖДУНАРОДНОГО ФИНАНСОВОГО ЦЕНТРА АСТАНА. Упорядник, p.118.",
      "Turdialiev, M. (2023). Legal Discussion of Metaverse Law. International Journal of Cyber Law, 1(3).",
    ],
    pdf: "/pdf/DUAL-LEGAL-SYSTEM-IN-INTERMATIONAL-FINANCIAL-CENTERS.pdf",
    google_pdf: "/pdf/DUAL-LEGAL-SYSTEM-IN-INTERMATIONAL-FINANCIAL-CENTERS.pdf",
  },
  // {
  //   "id": "2",
  //   "name": "ДВОЙНАЯ ПРАВОВАЯ СИСТЕМА В МЕЖДУНАРОДНЫХ ФИНАНСОВЫХ ЦЕНТРАХ",
  //   "autor": "Turdialiev Muhammadali Po`latjon o`g`li",
  //   "pdf": "/pdf/DUAL-LEGAL-SYSTEM-IN-INTERMATIONAL-FINANCIAL-CENTERS.pdf"
  // },
  // {
  //   "id": "3",
  //   "name": "ДВОЙНАЯ ПРАВОВАЯ СИСТЕМА В МЕЖДУНАРОДНЫХ ФИНАНСОВЫХ ЦЕНТРАХ",
  //   "autor": "Turdialiev Muhammadali Po`latjon o`g`li",
  //   "pdf": "/pdf/DUAL-LEGAL-SYSTEM-IN-INTERMATIONAL-FINANCIAL-CENTERS.pdf"
  // },
  // {
  //   "id": "4",
  //   "name": "ДВОЙНАЯ ПРАВОВАЯ СИСТЕМА В МЕЖДУНАРОДНЫХ ФИНАНСОВЫХ ЦЕНТРАХ",
  //   "autor": "Turdialiev Muhammadali Po`latjon o`g`li",
  //   "pdf": "/pdf/DUAL-LEGAL-SYSTEM-IN-INTERMATIONAL-FINANCIAL-CENTERS.pdf"
  // },
  // {
  //   "id": "5",
  //   "name": "ДВОЙНАЯ ПРАВОВАЯ СИСТЕМА В МЕЖДУНАРОДНЫХ ФИНАНСОВЫХ ЦЕНТРАХ",
  //   "autor": "Turdialiev Muhammadali Po`latjon o`g`li",
  //   "pdf": "/pdf/DUAL-LEGAL-SYSTEM-IN-INTERMATIONAL-FINANCIAL-CENTERS.pdf"
  // },
  // {
  //   "id": "6",
  //   "name": "ДВОЙНАЯ ПРАВОВАЯ СИСТЕМА В МЕЖДУНАРОДНЫХ ФИНАНСОВЫХ ЦЕНТРАХ",
  //   "autor": "Turdialiev Muhammadali Po`latjon o`g`li",
  //   "pdf": "/pdf/DUAL-LEGAL-SYSTEM-IN-INTERMATIONAL-FINANCIAL-CENTERS.pdf"
  // },
  // {
  //   "id": "7",
  //   "name": "ДВОЙНАЯ ПРАВОВАЯ СИСТЕМА В МЕЖДУНАРОДНЫХ ФИНАНСОВЫХ ЦЕНТРАХ",
  //   "autor": "Turdialiev Muhammadali Po`latjon o`g`li",
  //   "pdf": "/pdf/DUAL-LEGAL-SYSTEM-IN-INTERMATIONAL-FINANCIAL-CENTERS.pdf"
  // },
  // {
  //   "id": "8",
  //   "name": "ДВОЙНАЯ ПРАВОВАЯ СИСТЕМА В МЕЖДУНАРОДНЫХ ФИНАНСОВЫХ ЦЕНТРАХ",
  //   "autor": "Turdialiev Muhammadali Po`latjon o`g`li",
  //   "pdf": "/pdf/DUAL-LEGAL-SYSTEM-IN-INTERMATIONAL-FINANCIAL-CENTERS.pdf"
  // },
  // {
  //   "id": "9",
  //   "name": "ДВОЙНАЯ ПРАВОВАЯ СИСТЕМА В МЕЖДУНАРОДНЫХ ФИНАНСОВЫХ ЦЕНТРАХ",
  //   "autor": "Turdialiev Muhammadali Po`latjon o`g`li",
  //   "pdf": "/pdf/DUAL-LEGAL-SYSTEM-IN-INTERMATIONAL-FINANCIAL-CENTERS.pdf"
  // },
  // {
  //   "id": "10",
  //   "name": "ДВОЙНАЯ ПРАВОВАЯ СИСТЕМА В МЕЖДУНАРОДНЫХ ФИНАНСОВЫХ ЦЕНТРАХ",
  //   "autor": "Turdialiev Muhammadali Po`latjon o`g`li",
  //   "pdf": "/pdf/DUAL-LEGAL-SYSTEM-IN-INTERMATIONAL-FINANCIAL-CENTERS.pdf"
  // },
  // {
  //   "id": "2",
  //   "name": "O‘zbekistonda probatsiya institutining tashkil etilishi va xorijiy tajriba",
  //   "autor": "Quvonch Qodirov",
  //   "pdf": "/pdf/2.pdf"
  // },
  // {
  //   "id": "3",
  //   "name": "Маҳкумни жазо ўташни давом эттириш учун фуқароси бўлган давлатга топшириш масалалари",
  //   "autor": "Олим Саидов",
  //   "pdf": "/pdf/3.pdf"
  // },
  // {
  //   "id": "4",
  //   "name": "O‘zbekiston respublikasi markaziy saylov komissiyasining huquqiy holatini takomillashtirish",
  //   "autor": "Sanjarbek Jumaniyazov , Bekzod Matyakubov",
  //   "pdf": "/pdf/4.pdf"
  // },
  // {
  //   "id": "5",
  //   "name": "Iqtisodiyotni transformatsiya qilishda innovatsion faoliyatning o‘rni va ahamiyati",
  //   "autor": "Maxidil Olimova , Moxirabonu Abdusattorova ",
  //   "pdf": "/pdf/5.pdf"
  // }
];
