import {useState, useEffect} from 'react'
import './articles.css'
import { Link } from 'react-router-dom'
import Modal from '../modal/Modal'
import { useTranslation } from 'react-i18next';

import {articles} from '../../data' 

// import {journalData} from '../../data'

function Articles({journalData}) {

    const {t} = useTranslation();
    const [pageData, setPageData] = useState([])
    const [page, setPage] = useState(1)
    const [ pageCount, setPageCount] = useState(0)
    console.log(pageCount);

    const [showModal, setShowModal] = useState(false)
    const closeModal = () => {
            setShowModal(!showModal)
        };

    //handle next
    const handleNext = ()=> {
        if (page === pageCount) return page;
        setPage(page + 1)
    }

    //handle previos
    const Previos = ()=> {
        if (page === 1) return page;
        setPage(page - 1)
    }

    

    // useEffect(()=> {
    //     window.scrollTo({ top: 0, behavior: 'smooth' });
    // }, [page])

    // useEffect(()=> {
    //     const pagedatacount = Math.ceil(journalData.articles.length/2); 
    //     setPageCount(pagedatacount)

    //     if(page){
    //         const LIMIT = 5;
    //         const skip = LIMIT * page
    //         const dataskip = journalData.articles.slice(page === 1 ? 0 : skip - LIMIT, skip)
    //         setPageData(dataskip)
    //     }
    // }, [journalData.articles])



  return (
    <article > 
        {articles.map((value) => {
            return(
                <div className='main-article shadow p-4' key={value.id}  itemscope itemtype = "https://schema.org/ScholarlyArticle">
                    <div className="name-article">
                       <Link to="/turdialiyev_m"> <span itemprop="name">{value.name}</span></Link>
                    </div>
                    <div className="autor-article my-3">
                        <span itemprop="autor">{value.autor}</span>
                    </div>
                        <a href={articles[0].pdf} itemprop="url" className='pdf-button' download={value.name}>{t('btn.download')}</a>   
                </div>
            )
        })}

        <div className="breadcrumb">
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                    <li className="page-item">
                        <a className="page-link" href="#" aria-label="Previous" onClick={Previos} disabled={page === 1}>
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    {/* <li className="page-item active"><a className="page-link" href="#">1</a></li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li> */}
                    <li className="page-item">
                        <a className="page-link" href="#" aria-label="Next" onClick={handleNext} disabled={page === pageCount}>
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </article >
  )
}

export default Articles