import React from 'react'
import Counter from '../../components/counter/Counter'
import Yandexmaps from '../../components/yandexmaps/Yandexmaps'
import { useTranslation } from 'react-i18next';
import './about.css'
import Location from '../../components/location/Location';

function About() {
  const {t} = useTranslation();
  return (
    <>
      <div className="about-banner-wrap banner-space about-us-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="about-banner-content text-center">
                <h1 className="mb-15 text-white">{t('about.title')}</h1>
                <h5 className="font-weight--normal text-white">{t('about.decs')}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div className="about-info">
        <div className="container">
          <h4 className='text-center mb-4'>{t('about.company_name')} </h4>
          <p><b> {t('about.company_name')} </b> {t('about.about_company')} </p>
          <p><b> {t('about.goal')} </b>{t('about.goal_content')}</p>
          <p>{t('about.goal_content_2')}</p>
          <h4 className='text-center mb-4'>{t('about.tasks')}</h4>
          <ul>
            <li>{t('about.tasks_1')}</li>
            <li>{t('about.tasks_2')}</li>
            <li>{t('about.tasks_3')}</li>
            <li>{t('about.tasks_4')}</li>
            <li>{t('about.tasks_5')}</li>
            <li>{t('about.tasks_6')}</li>
          </ul><br />
          <p><b>{t('about.specialization')} : </b>{t('about.specialization_content')}</p>
          <p><b>{t('about.period')} </b> {t('about.period_content')}</p>
        </div>
      </div>
      <Counter />
      <Location />
    </>
  )
}

export default About